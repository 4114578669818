import { getShortcutFromShortcutName } from "../../actions/shortcuts";
import { useI18n } from "../../i18n";
import {
  useExcalidrawSetAppState,
  useExcalidrawActionManager,
  useExcalidrawElements,
} from "../App";
import {
  ExportIcon,
  ExportImageIcon,
  HelpIcon,
  LoadIcon,
  save,
  TrashIcon,
  usersIcon,
} from "../icons";
import DropdownMenuItem from "../dropdownMenu/DropdownMenuItem";
import {
  actionClearCanvas,
  actionLoadScene,
  actionSaveToActiveFile,
  actionShortcuts,
} from "../../actions";

import "./DefaultItems.scss";
import clsx from "clsx";
import { useSetAtom } from "jotai";
import { activeConfirmDialogAtom } from "../ActiveConfirmDialog";
import { jotaiScope } from "../../jotai";
import { openConfirmModal } from "../OverwriteConfirm/OverwriteConfirmState";
import Trans from "../Trans";

export const LoadScene = () => {
  const { t } = useI18n();
  const actionManager = useExcalidrawActionManager();
  const elements = useExcalidrawElements();

  if (!actionManager.isActionEnabled(actionLoadScene)) {
    return null;
  }

  const handleSelect = async () => {
    if (
      !elements.length ||
      (await openConfirmModal({
        title: t("overwriteConfirm.modal.loadFromFile.title"),
        actionLabel: t("overwriteConfirm.modal.loadFromFile.button"),
        color: "warning",
        description: (
          <Trans
            i18nKey="overwriteConfirm.modal.loadFromFile.description"
            bold={(text) => <strong>{text}</strong>}
            br={() => <br />}
          />
        ),
      }))
    ) {
      actionManager.executeAction(actionLoadScene);
    }
  };

  return (
    <DropdownMenuItem
      icon={LoadIcon}
      onSelect={handleSelect}
      data-testid="load-button"
      shortcut={getShortcutFromShortcutName("loadScene")}
      aria-label={t("buttons.load")}
    >
      {t("buttons.load")}
    </DropdownMenuItem>
  );
};
LoadScene.displayName = "LoadScene";

export const SaveToActiveFile = () => {
  const { t } = useI18n();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionSaveToActiveFile)) {
    return null;
  }

  return (
    <DropdownMenuItem
      shortcut={getShortcutFromShortcutName("saveScene")}
      data-testid="save-button"
      onSelect={() => actionManager.executeAction(actionSaveToActiveFile)}
      icon={save}
      aria-label={`${t("buttons.save")}`}
    >{`${t("buttons.save")}`}</DropdownMenuItem>
  );
};
SaveToActiveFile.displayName = "SaveToActiveFile";

// export const SaveAsImage = () => {
//   const setAppState = useExcalidrawSetAppState();
//   const { t } = useI18n();
//   return (
//     <DropdownMenuItem
//       icon={ExportImageIcon}
//       data-testid="image-export-button"
//       onSelect={() => setAppState({ openDialog: "imageExport" })}
//       shortcut={getShortcutFromShortcutName("imageExport")}
//       aria-label={t("buttons.exportImage")}
//     >
//       {t("buttons.exportImage")}
//     </DropdownMenuItem>
//   );
// };
// SaveAsImage.displayName = "SaveAsImage";

// export const Help = () => {
//   const { t } = useI18n();

//   const actionManager = useExcalidrawActionManager();

//   return (
//     <DropdownMenuItem
//       data-testid="help-menu-item"
//       icon={HelpIcon}
//       onSelect={() => actionManager.executeAction(actionShortcuts)}
//       shortcut="?"
//       aria-label={t("helpDialog.title")}
//     >
//       {t("helpDialog.title")}
//     </DropdownMenuItem>
//   );
// };
// Help.displayName = "Help";

// export const ClearCanvas = () => {
//   const { t } = useI18n();

//   const setActiveConfirmDialog = useSetAtom(
//     activeConfirmDialogAtom,
//     jotaiScope,
//   );
//   const actionManager = useExcalidrawActionManager();

//   if (!actionManager.isActionEnabled(actionClearCanvas)) {
//     return null;
//   }

//   return (
//     <DropdownMenuItem
//       icon={TrashIcon}
//       onSelect={() => setActiveConfirmDialog("clearCanvas")}
//       data-testid="clear-canvas-button"
//       aria-label={t("buttons.clearReset")}
//     >
//       {t("buttons.clearReset")}
//     </DropdownMenuItem>
//   );
// };
// ClearCanvas.displayName = "ClearCanvas";

export const Export = () => {
  const { t } = useI18n();
  const setAppState = useExcalidrawSetAppState();
  return (
    <DropdownMenuItem
      icon={ExportIcon}
      onSelect={() => {
        setAppState({ openDialog: "jsonExport" });
      }}
      data-testid="json-export-button"
      aria-label={t("buttons.export")}
    >
      {t("buttons.export")}
    </DropdownMenuItem>
  );
};
Export.displayName = "Export";

export const LiveCollaborationTrigger = ({
  onSelect,
  isCollaborating,
}: {
  onSelect: () => void;
  isCollaborating: boolean;
}) => {
  const { t } = useI18n();
  return (
    <DropdownMenuItem
      data-testid="collab-button"
      icon={usersIcon}
      className={clsx({
        "active-collab": isCollaborating,
      })}
      onSelect={onSelect}
    >
      {t("labels.liveCollaboration")}
    </DropdownMenuItem>
  );
};

LiveCollaborationTrigger.displayName = "LiveCollaborationTrigger";
